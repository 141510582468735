import { Outlet } from "@remix-run/react";
import { AppLogo } from "#app/components/ui/app/app-logo.tsx";

export default function AuthLayout() {
    return (
        <div className="mx-auto max-w-lg w-full px-4 pt-8 pb-8">
            <div className="w-full px-4 pt-4 pb-8 bg-white/70 rounded-xl overflow-hidden shadow-md flex flex-col gap-8">
                <AppLogo />
                <Outlet />
            </div>
        </div>
    )
}